import axios from 'axios'
import { api } from '@/api/service/endpoints'

// export const api_webauthnAuth = () =>               axios.get('api_webauthnAuth', api.WEBAUTHN_LOGIN)
export const api_webauthnAuthOptions = (data: any) =>               axios.post(api.WEBAUTHN_LOGIN_OPTIONS, data)
export const api_webauthnAuth = (data: any) =>                      axios.post(api.WEBAUTHN_LOGIN_AUTH, data)
export const api_userLogin = (data: any) =>                         axios.post(api.USER_LOGIN, data)
export const api_webauthnKeyOptions = (data: any) =>                axios.post(api.WEBAUTHN_KEY_OPTIONS, data, {
  headers: {
    Authorization: `Bearer ${window.localStorage.getItem('access_token')}`,
  },
})
export const api_webauthnKeys = (data: any) =>                axios.post(api.WEBAUTHN_LOGIN_AUTH, data, {
  headers: {
    Authorization: `Bearer ${window.localStorage.getItem('access_token')}`,
  },
})

export const api_webauthnKeyDestroy = (data: any) =>                         axios.delete(api.WEBAUTHN_DESTROY(data), {
  headers: {
    Authorization: `Bearer ${window.localStorage.getItem('access_token')}`,
  },
})


