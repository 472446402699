export const webApiDomain = 'https://autoro.ru/webauthn'
export const webApiDomainAuth = 'https://autoro.ru'
export const webApiDomainAsbin = 'https://webauthn.asbin.net/webauthn'
export const apiDomain = 'https://bcoins.dterra.eu'
export const GRANT_TYPE = 'password'
export const CLIENT_ID = 2
export const CLIENT_SECRET = 'xaZawT4WR6gxyCJgZAPc8uV1CtOQxB6M7pHwHnOA'

export const api = {
  USER_AUTH: `${apiDomain}/oauth/token`,
  USER_INFO: `${apiDomain}/api/user`,
  DEVICE_TOKEN_CREATE: `${apiDomain}/api/user/token`,
  DEVICE_TOKEN_DELETE: `${apiDomain}/api/user/token/delete`,
  WEBAUTHN_LOGIN: `${webApiDomain}/auth`,
  USER_LOGIN: `${webApiDomainAuth}/oauth/token`,
  WEBAUTHN_LOGIN_OPTIONS: `${webApiDomain}/auth/options`,
  WEBAUTHN_KEY_OPTIONS: `${webApiDomain}/keys/options`,
  WEBAUTHN_LOGIN_AUTH: `${webApiDomain}/keys`,
  WEBAUTHN_DESTROY: (id: any) => `${webApiDomain}/keys/${id}`
} as const

type Keys = keyof typeof api
export type TEndpoint = typeof api[Keys]
